import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { HeaderComponent } from './header/header.component';
import { MainComponent } from "./pages/main/main.component";
import { LandingComponent } from "./pages/landing/landing.component";

import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatToolbar, MatToolbarModule } from "@angular/material/toolbar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";

// import { ProfileComponent } from './profile/profile.component';
// import { CreateQrComponent } from './create-qr/create-qr.component';
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { InvalidNumberPipe } from "./pages/pipes/invalid.pipe";

// import { CacheInterceptor } from './interceptor/cache-interceptor';
// import { HistoryComponent } from './history/history.component';

@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    MainComponent,
    LandingComponent,
    // ProfileComponent,
    // CreateQrComponent,
    // HistoryComponent
    InvalidNumberPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    MatIconModule,
    MatBadgeModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatInputModule,
    MatSnackBarModule,
    FormsModule,
    HttpClientModule,
    MatGridListModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
  ],
  providers: [],
  exports: [InvalidNumberPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
