<mat-toolbar color="white">
  <div class="logo-wrapper">
    <img src="assets/Revenuerecovery-01.png" />
    <!-- <span>Revenue</span> -->
  </div>
  <!-- <span>Overbooking - Calculator</span> -->
  <!-- <mat-button-toggle-group>
    <mat-button-toggle color="primary" [checked]="true" value="0" (click)="changeView(0)">Media</mat-button-toggle>
    <mat-button-toggle color="accent" value="1" (click)="changeView(1)">Mediana30</mat-button-toggle>
    <mat-button-toggle color="accent" value="2" (click)="changeView(2)">MedianaSett</mat-button-toggle>
  </mat-button-toggle-group> -->
  <span class="spacer"></span>
  <!-- Reload data: -->
  <button mat-icon-button (click)="refresh(0)" [disabled]="!loggedIn" color="primary"><mat-icon>save_alt</mat-icon></button>
  <button mat-icon-button (click)="refresh(4)" [disabled]="!loggedIn" color="primary"><mat-icon>inventory_2</mat-icon></button>
  <button mat-icon-button (click)="refresh(7)" [disabled]="!loggedIn" color="primary"><mat-icon>add_circle_outline</mat-icon></button>
  
  <div>
    <!-- <input
      (change)="fileChange($event)"
      type="file"
      class="custom-file-input"
      id="inputGroupFile01"
      aria-describedby="inputGroupFileAddon01"
    /> -->
    <!-- <div class="form-group">
      <label for="file">Choose File</label>
      <input type="file" id="file" (change)="fileChange($event.target.files)" />
    </div>
    <button mat-icon-button (click)="upload()">
      <mat-icon>upload</mat-icon>
    </button> -->
    <!-- <input ng2FileSelect type="file" accept=".xlsx" [uploader]="uploader" /> -->
  </div>
  <!-- <button mat-icon-button [disabled]="!loggedIn" color="primary">
    <mat-icon>upload</mat-icon>
  </button> -->
  <button mat-icon-button (click)="openApp()" color="primary">
    <mat-icon>store</mat-icon>
  </button>
</mat-toolbar>

<div *ngIf="!loggedIn" class="login-block">
  <mat-form-field class="example-full-width">
    <mat-label>Username</mat-label>
    <input matInput [(ngModel)]="username" />
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Password</mat-label>
    <input matInput [(ngModel)]="password" />
  </mat-form-field>

  <div class="example-full-width">
    <button mat-raised-button (click)="logIn()">Entra</button>
  </div>

  <mat-form-field *ngIf="showError" class="example-full-width">
    <label>Errore Login</label>
  </mat-form-field>
</div>

<div class="container mat-elevation-z8" [style.display]="!loggedIn ? 'none' : 'block'">
  <div class="form" style="display: none">
    <mat-form-field floatPlaceholder="never" color="accent">
      <input matInput #filter placeholder="Filter date" />
    </mat-form-field>
  </div>

  <!-- <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Giorno</mat-label>
          <input matInput #dayInput placeholder="giorno" [value]="day" />
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Mese</mat-label>
          <input matInput #monthInput placeholder="mese" [value]="month" />
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Anno</mat-label>
          <input matInput #yearInput placeholder="anno" [value]="year" />
        </mat-form-field>
      </td>
      <td>
        <mat-button-toggle-group>
          <mat-button-toggle
            color="primary"
            [checked]="true"
            value="Giolli"
            (click)="load('001')"
            >Giolli</mat-button-toggle
          >
          <mat-button-toggle
            color="accent"
            value="Hotel del Sole"
            (click)="load('002')"
            >Hotel del Sole</mat-button-toggle
          >
        </mat-button-toggle-group>
      </td>
    </tr>
  </table> -->

  <mat-progress-spinner mode="indeterminate" *ngIf="dataSource?.loading"></mat-progress-spinner>

  <mat-table #table [dataSource]="dataSource" matSort class="mat-cell" [style.display]="dataSource.loading ? 'none' : 'block'">
    <!-- ng update @angular/cli @angular/core -->
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->

    <ng-container matColumnDef="DATA">
      <mat-header-cell *matHeaderCellDef>Data</mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index">
        <!-- <span>{{dayOfWeek(row.day)}} </span> -->
        <span *ngIf="i === dataSource?.renderedData?.length - 7"> Oggi </span>
        <span *ngIf="i !== dataSource?.renderedData?.length - 7">
          {{ row.day }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Revenue1">
      <mat-header-cell *matHeaderCellDef>REV (0 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.revenue | invalid | number : '1.2-2' }} <span class="material-icons table">euro_symbol</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="Revenue2">
      <mat-header-cell *matHeaderCellDef>REV (-1 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.currentMinusOne.revenue | invalid | number : '1.2-2' }}
        <span class="material-icons table">euro_symbol</span></mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="RevenueDiff">
      <mat-header-cell *matHeaderCellDef>REV (Diff)</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span [style.color]="row.revenueDiff >= 0 ? 'green' : 'red'"
          >{{ row.revenueDiff | invalid | number : '1.2-2' }} <span class="material-icons table">euro_symbol</span></span
        >
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="RevenueFC">
      <mat-header-cell *matHeaderCellDef>RFC</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span [style.color]="row.previsioneRev >= 0 ? 'green' : 'red'">{{ row.previsioneRev | invalid | number : '1.2-2' }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="RevenueD">
      <mat-header-cell *matHeaderCellDef>RD</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span [style.color]="row.discrepanzaRev >= 0 ? 'green' : 'red'">{{ row.discrepanzaRev| invalid | number : '1.2-2' }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ROOMS_SOLD1">
      <mat-header-cell *matHeaderCellDef>0 DBA</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.sold | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ROOMS_SOLD2">
      <mat-header-cell *matHeaderCellDef>-1 DBA</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.currentMinusOne.sold | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ROOMS_SOLD_DIFF">
      <mat-header-cell *matHeaderCellDef>DBA (Diff)</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span [style.color]="row.dbaDiff >= 0 ? 'green' : 'red'">{{ row.dbaDiff | invalid }}</span>
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="median1">
      <mat-header-cell *matHeaderCellDef>Med1</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.median30Prev | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="median2">
      <mat-header-cell *matHeaderCellDef>Med2</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrevAfterMedian30 | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="medianw1">
      <mat-header-cell *matHeaderCellDef>Med @1</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.medianWeekPrev | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="medianw2">
      <mat-header-cell *matHeaderCellDef>Med W2</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrevAfterMedianWeek | invalid }}</mat-cell>
    </ng-container> 

    <ng-container matColumnDef="soldPrevDiffMedian30">
      <mat-header-cell *matHeaderCellDef>PREV30 (-1 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrevDiffMedian30 | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="soldPrevSoldDiffMedian30">
      <mat-header-cell *matHeaderCellDef>PREV30 (+1 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrevSoldDiffMedian30 | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="soldPrevDiffMedianWeek">
      <mat-header-cell *matHeaderCellDef>PREV-W (-1 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrevDiffMedianWeek | invalid }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="soldPrevSoldDiffMedianWeek">
      <mat-header-cell *matHeaderCellDef>PREV-W (+1 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrevSoldDiffMedianWeek | invalid }}</mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="SOLD_PREV">
      <mat-header-cell *matHeaderCellDef>PREV (-1 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrev | invalid }}</mat-cell>
    </ng-container> 
 
    <ng-container matColumnDef="SOLD_PREV2">
      <mat-header-cell *matHeaderCellDef>PREV (+1 DBA)</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.soldPrevAfter | invalid }}</mat-cell>
    </ng-container> 

    <ng-container matColumnDef="SOLD_PREV_DIFF">
      <mat-header-cell *matHeaderCellDef>FC</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span [style.color]="row.previsione >= 0 ? 'green' : 'red'">{{ row.previsione | invalid }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="DIFF_SOLD_PREV_DIFF">
      <mat-header-cell *matHeaderCellDef>D</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span [style.color]="row.discrepanza >= 0 ? 'green' : 'red'">{{ row.discrepanza | invalid }} </span>
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="DIFF_SOLD_PREV_DIFF_REV">
      <mat-header-cell *matHeaderCellDef>Revenue OB</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span [style.color]="row.soldPrevSoldDiff * row.revenueDiff > 0 ? 'green' : 'red'"
          >{{ row.soldPrevSoldDiff * row.revenueDiff | invalid }}
        </span>
      </mat-cell>
    </ng-container> -->

    <!-- actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <button mat-icon-button color="primary" (click)="addNew()">
          <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
        </button>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let i = index">
        <button mat-icon-button color="accent" (click)="startEdit(i, row.id, row.title, row.state, row.url, row.data, row.rooms_sold)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button mat-icon-button color="accent" (click)="deleteItem(i, row.id, row.title, row.state, row.url)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="no-results" [style.display]="!dataSource.loading && dataSource?.renderedData?.length === 0 ? 'block' : 'none'">
    No results
  </div>

  <!-- <div >{{ dataService.dataError }}</div> -->

  <mat-paginator
    #paginator
    [style.display]="dataSource.loading ? 'none' : 'block'"
    [length]="dayTotal"
    [pageIndex]="dayIndex"
    [pageSize]="200"
    [pageSizeOptions]="[15, 30, 100, 200]"
    [disabled]="true"
  >
  </mat-paginator>
</div>
