import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DataService } from "../../services/data.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Issue } from "../../models/issue";
import { DataSource } from "@angular/cdk/collections";
// import { AddDialogComponent } from "../../dialogs/add/add.dialog.component";
// import { EditDialogComponent } from "../../dialogs/edit/edit.dialog.component";
// import { DeleteDialogComponent } from "../../dialogs/delete/delete.dialog.component";
import { BehaviorSubject, fromEvent, merge, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FileUploader } from "ng2-file-upload";
// import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { RRDataSource } from "../RRDataSource";

@Component({
  selector: "main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  basedisplayedColumnsAdmin = ["DATA", "ROOMS_SOLD1", "ROOMS_SOLD2", "ROOMS_SOLD_DIFF"];
  basedisplayedColumns = ["DATA", "ROOMS_SOLD1", "ROOMS_SOLD2", "ROOMS_SOLD_DIFF"];

  // middleDisplayedColumnsAdmin = ["SOLD_PREV2", "SOLD_PREV", "SOLD_PREV_DIFF", "DIFF_SOLD_PREV_DIFF"];
  middleDisplayedColumnsAdmin = [ "SOLD_PREV_DIFF", "DIFF_SOLD_PREV_DIFF"];
  middleDisplayedColumns = ["SOLD_PREV_DIFF"];
  finalDisplayedColumnsAdmin = ["Revenue1", "Revenue2", "RevenueDiff", "RevenueFC", "RevenueD"];
  finalDisplayedColumns = ["Revenue2", "Revenue1", "RevenueDiff"];

  isAdmin = false;
  displayedColumns = [];

  RRDatabase: DataService | null;
  dataSource: RRDataSource | null;
  index: number;
  id: number;

  dayIndex = 0;
  dayTotal = 31;

  fileToUpload;

  uploader: FileUploader;

  username = window.location.hostname === "localhost" ? "overbooking" : "";
  password = window.location.hostname === "localhost" ? "admin22" : "";
  loggedIn = false;
  showError = false;

  day = "01";
  month = "02";
  year = new Date().getFullYear();
  view = 0;

  // uploadedFiles: Array<File>;

  uploadedFile: File;

  constructor(
    public httpClient: HttpClient // public dialog: MatDialog, // public dataService: DataService
  ) {}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;

  @ViewChild("dayInput", { static: true }) dayInput: ElementRef;
  @ViewChild("monthInput", { static: true }) monthInput: ElementRef;
  @ViewChild("yearInput", { static: true }) yearInput: ElementRef;

  setColumns() {
    this.displayedColumns = this.isAdmin
      ? this.basedisplayedColumnsAdmin.concat(this.middleDisplayedColumnsAdmin).concat(this.finalDisplayedColumnsAdmin)
      : this.basedisplayedColumns.concat(this.middleDisplayedColumns).concat(this.finalDisplayedColumns);
  }

  ngOnInit() {
    this.setColumns();
    this.loadData(0);

    // if (window.location.hostname === "localhost") this.loggedIn = true;

    this.uploader = new FileUploader({
      url: "http://localhost:3000/upload/002",
      removeAfterUpload: false,
      autoUpload: true,
      headers: [
        { name: "Content-Type", value: "multipart/form-data" },
        { name: "Access-Control-Allow-Origin", value: "*" },
        { name: "Access-Control-Allow-Methods", value: "POST, OPTIONS, GET" },
        { name: "Access-Control-Allow-Credentials", value: "true" },
      ],
    });
  }

  fileChange(element) {
    console.log(element);
    this.uploadedFile = element[0];
  }

  createAuthorizationHeader(headers: Headers) {
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
  }

  changeView(n) {
    this.view = n;
    if (n == 0) {
      this.displayedColumns = this.basedisplayedColumns
        .concat(["SOLD_PREV", "SOLD_PREV2", "SOLD_PREV_DIFF", "DIFF_SOLD_PREV_DIFF"])
        .concat(this.finalDisplayedColumns);
    } else if (n == 1) {
      this.displayedColumns = this.basedisplayedColumns
        .concat(["median1", "median2", "soldPrevDiffMedian30", "soldPrevSoldDiffMedian30"])
        .concat(this.finalDisplayedColumns);
    } else if (n == 2) {
      this.displayedColumns = this.basedisplayedColumns
        .concat(["medianw1", "medianw2", "soldPrevDiffMedianWeek", "soldPrevSoldDiffMedianWeek"])
        .concat(this.finalDisplayedColumns);
    }
  }
  upload() {
    let formData = new FormData();
    // for (var i = 0; i < this.uploadedFiles.length; i++) {
    //   formData.append(
    //     "uploads[]",
    //     this.uploadedFiles[i],
    //     this.uploadedFiles[i].name
    //   );
    // }
    /** In Angular 5, including the header Content-Type can invalidate your request */

    formData.append("upload", this.uploadedFile, this.uploadedFile.name);
    console.log(formData);
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    this.httpClient.post("http://localhost:3000/api/upload", formData).subscribe((response) => {
      console.log("response received is ", response);
    });
  }

  refresh(value) {
    this.loadData(value);
  }

  load(structureId) {
    let day = this.dayInput.nativeElement.value;
    let month = this.monthInput.nativeElement.value;
    let year = this.yearInput.nativeElement.value;
    this.loadData2(structureId, day + "-" + month + "-" + year);
  }

  logIn() {
    this.showError = false;
    if (this.username === "overbooking" && this.password === "admin22") {
      this.loggedIn = true;
      this.isAdmin = true;
    }
    if (this.username === "demorevenue" && this.password === "demo22") {
      this.loggedIn = true;
      this.isAdmin = false;
    } else {
      this.showError = true;
    }
    this.setColumns();
  }

  dayOfWeek(day) {
    return new Date(day).getDay();
  }

  // addNew() {
  //   const dialogRef = this.dialog.open(AddDialogComponent, {
  //     height: "600px",
  //     width: "800px",
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result === 1) {
  //       // After dialog is closed we're doing frontend updates
  //       // For add we're just pushing a new row inside DataService
  //       // this.RRDatabase.dataChange.value.push(
  //       //   this.dataService.getDialogData()
  //       // );
  //       this.refreshTable();
  //     }
  //   });
  // }

  openApp() {
    window.open("http://overbookingapp.com/app", "_blank");
  }

  // startEdit(
  //   i: number,
  //   id: number,
  //   title: string,
  //   state: string,
  //   url: string,
  //   data: string,
  //   rooms_sold: string
  // ) {
  //   this.id = id;
  //   // index row is used just for debugging proposes and can be removed
  //   this.index = i;
  //   console.log(this.index);
  //   const dialogRef = this.dialog.open(EditDialogComponent, {
  //     data: {
  //       id: id,
  //       title: title,
  //       state: state,
  //       url: url,
  //       data: data,
  //       rooms_sold: rooms_sold,
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result === 1) {
  //       // When using an edit things are little different, firstly we find record inside DataService by id
  //       const foundIndex = this.RRDatabase.dataChange.value.findIndex(
  //         (x) => x.id === this.id
  //       );
  //       // Then you update that record using data from dialogData (values you enetered)
  //       this.RRDatabase.dataChange.value[foundIndex] =
  //         this.dataService.getDialogData();
  //       // And lastly refresh table
  //       this.refreshTable();
  //     }
  //   });
  // }

  // deleteItem(i: number, id: number, title: string, state: string, url: string) {
  //   this.index = i;
  //   this.id = id;
  //   const dialogRef = this.dialog.open(DeleteDialogComponent, {
  //     data: { id: id, title: title, state: state, url: url },
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result === 1) {
  //       const foundIndex = this.RRDatabase.dataChange.value.findIndex(
  //         (x) => x.id === this.id
  //       );
  //       // for delete we use splice in order to remove single object from DataService
  //       this.RRDatabase.dataChange.value.splice(foundIndex, 1);
  //       this.refreshTable();
  //     }
  //   });
  // }

  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  /*   // If you don't need a filter or a pagination this can be simplified, you just use code from else block
    // OLD METHOD:
    // if there's a paginator active we're using it for refresh
    if (this.dataSource._paginator.hasNextPage()) {
      this.dataSource._paginator.nextPage();
      this.dataSource._paginator.previousPage();
      // in case we're on last page this if will tick
    } else if (this.dataSource._paginator.hasPreviousPage()) {
      this.dataSource._paginator.previousPage();
      this.dataSource._paginator.nextPage();
      // in all other cases including active filter we do it like this
    } else {
      this.dataSource.filter = '';
      this.dataSource.filter = this.filter.nativeElement.value;
    }*/

  public loadData(structureId) {
    this.RRDatabase = new DataService(this.httpClient);
    this.dataSource = new RRDataSource(this.RRDatabase, this.paginator, this.sort, structureId);
    fromEvent(this.filter.nativeElement, "keyup").subscribe(() => {
      if (!this.dataSource) {
        return;
      }
      this.dataSource.filter = this.filter.nativeElement.value;
    });

    this.dataSource.loading = true;
  }

  public loadData2(structureId, date) {
    console.log("loadData2", structureId, date);
    this.RRDatabase = new DataService(this.httpClient);
    this.dataSource = new RRDataSource(this.RRDatabase, this.paginator, this.sort, structureId, date);
    fromEvent(this.filter.nativeElement, "keyup").subscribe(() => {
      if (!this.dataSource) {
        return;
      }
      this.dataSource.filter = this.filter.nativeElement.value;
    });
    this.dataSource.loading = true;
  }
}
