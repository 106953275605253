<!-- <mat-toolbar color="white">
  <div class="logo-wrapper">
    <img src="assets/Revenuerecovery-01.png" />
  </div>
  <span class="spacer"></span>
</mat-toolbar>
<div class="container text-center">
  <br /><br />
  <span>
    Gentile albergatore,
    <br />
    siamo lieti di presentarle un prodotto esclusivo, studiato dal nostro team
    di esperti per favorire e incoraggiare le politiche di revenue management
    all’interno delle strutture ricettive.
    <br /><br />
    <strong>Revenue Recovery</strong><br /><br />

    È un algoritmo previsionale che analizza la fuoriuscita di prenotazioni
    all'ultimo minuto suggerendo all'albergatore la strategia per contenere e/o
    sfruttare questo fenomeno tramite politiche di oversale.
    <br />
    Oggi le previsioni legate all'occupazione si basano su dati del tutto
    approssimativi, facendo perdere a voi albergatori tempo e opportunità di
    massimizzazione del vostro revenue.
    <br />
    Il nostro scopo è quello di fornirvi uno strumento chiaro e affidabile che
    possa suggerire nuovi spunti di analisi per le vostre strategie commerciali.
    <br />
    Il software si integra direttamente con i gestionali alberghieri fornendo al
    manager statistiche in tempo reale. Insieme a Revenue Recovery forniremo
    anche
    <br /><br />
    <strong>Overbooking App</strong><br /><br />

    la prima applicazione che permette di gestire gli overbooking in pochi
    minuti, mettendo in contatto albergatori e host per una gestione rapida e
    fluida del cliente in esubero.
    <br />
    Nessun costo sulle transazioni e nessuna commissione.

    <br />
    Conosci già il superbonus?

    <br />
    Ci sono 2 forme di incentivo:

    <br />
    Credito d’imposta fino all’80% delle spese ammissibili, utilizzabile solo in
    compensazione dall’anno successivo a quello in cui gli interventi sono stati
    realizzati, e comunque entro il 31 dicembre 2025. Il credito d’imposta è
    cedibile, in tutto o in parte, a soggetti terzi (banche e altri intermediari
    finanziari)

    <br />
    Contributo a fondo perduto fino al 50% delle spese ammissibili, per un
    importo massimo di 100.000 euro. Il contributo viene erogato con bonifico
    bancario in un’unica soluzione, a conclusione degli interventi. È tuttavia
    possibile ottenere un anticipo fino al 30% dell’importo totale.

    <br /><br />
    <a
      href="https://www.invitalia.it/cosa-facciamo/rafforziamo-le-imprese/incentivi-imprese-turistiche-ifit/presenta-la-domanda"
      >Maggiori informazioni su come presentare la domanda clicca qui</a
    >

    <br /><br />
    <span style="text-decoration: underline">Come richiederlo?</span>

    <br />
    La domanda può essere presentata, dalle 12.00 del 28 febbraio alle 17.00 del
    30 marzo 2022 ed è necessario possedere:

    <ul>
      <li>Identità digitale (SPID, CNS, CIE)</li>
      <li>Firma digitale</li>
      <li>Indirizzo di posta elettronica certificata (PEC).</li>
    </ul>
    <br />

    <a
      href="https://www.invitalia.it/cosa-facciamo/rafforziamo-le-imprese/incentivi-imprese-turistiche-ifit/modulistica"
      >Per tutta la modulistica necessaria, clicca qui</a
    >

    <br />
    In allegato troverà il nostro preventivo da allegare alla domanda.
  </span>
  <br /><br /><br />


  <br />
  <i
    >Ti informiamo che, ai sensi della disciplina del Superbonus Turismo (DL
    152/2021 art. 1 e DL 83/2014 art. 9, Avviso Min. Turismo 23/12/2021 ed
    Elenco spese ammissibili Min. Turismo 4/2/2022), i servizi offerti da
    Overbooking S.R.L. rientrano nel Superbonus e quindi per il loro costo può
    essere richiesto il credito d'imposta e il contributo a fondo perduto.
    <br />
    Le spese valide per richiedere il bonus comprendono quelle sostenute dal
    7/11/2021 fino al 31/12/2024.
  </i>
</div> -->
<mat-toolbar color="primary">
  <div class="header">I nostri prodotti</div></mat-toolbar
>
<div class="container text-center">
  <div class="title-logo"><img src="assets/Revenuerecovery-01.png" /></div>
  <div class="big-img"><img src="assets/FOTO1.jpg" /></div>
  <div class="product">
    <div class="title-list">Dedicato a :</div>
    <ul class="item-list">
      <li>
        chi utilizza dati previsionali per programmare la propria strategia
        commerciale
      </li>
      <li>chi vuole massimizzare l’occupazione</li>
      <li>chi è appassionato di numeri e statistiche</li>
    </ul>

    <div class="title-list">Cosa fa:</div>
    <ul class="item-list">
      <li>analizza ed elabora i dati storici</li>
      <li>
        prevede l’indice di occupazione al netto delle variabili distorsive
      </li>
      <li>
        evidenzia dati fondamentali per costruire nuove strategie di revenue
      </li>
      <li>si integra facilmente con tutti i PMS</li>
    </ul>

    <div class="title-list">Quanto costa (abbonamento annuo):</div>
    <ul class="item-list">
      <li>1-30 camere: € 360 + Iva</li>
      <li>31-50 camere: € 450 + Iva</li>
      <li>da 51 camere: € 500 + Iva</li>
    </ul>

    <button
      (click)="sendMail()"
      mat-raised-button
      color="primary"
      style="width: 100%"
    >
      Richiedi una demo
    </button>
    <a id="link" style="visibility: hidden" [href]="emailstring" target="_top"
      >Richiedi una demo</a
    >
  </div>

  <div class="title-logo"><img src="assets/logo-orizzontale.png" /></div>
  <div class="big-img"><img src="assets/FOTO2.jpg" /></div>
  <div class="product">
    <div class="title-list">Dedicato a :</div>
    <ul class="item-list">
      <li>chiunque si trovi a gestire un overbooking</li>
      <li>
        chi adotta una strategia di oversale e vuole snellire il processo di
        rilocazione
      </li>
      <li>
        chi intende massimizzare la propria occupazione rendendosi disponibile
        ad accogliere i clienti in esubero dei colleghi
      </li>
    </ul>

    <div class="title-list">Cosa fa:</div>
    <ul class="item-list">
      <li>
        crea un network di professionisti del settore con lo scopo di
        supportarsi nella pratica di rilocazione di un viaggiatore in modo
        fluido
      </li>
      <li>mette in contatto albergatori e host in pochi minuti</li>
      <li>
        automatizza il processo di rilocazione, senza costi di transazione o %
        di commissione
      </li>
      <li>
        permette di offrire al viaggiatore una soluzione rapida e professionale
      </li>
    </ul>

    <div class="title-list">Quanto costa (abbonamento annuo):</div>
    <ul class="item-list">
      <li>1 struttura : € 69 + Iva</li>
      <li>2 o più strutture: € 99 + Iva</li>
    </ul>
  </div>
</div>
