import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Issue, IssueResponse } from "../models/issue";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class DataService {
  // private readonly API_URL = 'https://api.github.com/repos/angular/angular/issues';
  //public readonly API_URL = "//localhost:4000";
	public readonly API_URL = "http://212.237.10.94:4000";  
// demopath = "excel/get/001/4";
  demopath1 = "get/001/4";
  demopath2 = "get/001/7";
  getAllDemo = "getAllDemo";

  dataChange: BehaviorSubject<Issue[]> = new BehaviorSubject<Issue[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;

  dataError = false;

  constructor(private httpClient: HttpClient) {}

  get data(): Issue[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  /** CRUD METHODS */
  getAllIssues(structureId): void {
    console.log("getAllIssues", structureId);
    let demopath1 = structureId === 0 ? this.getAllDemo : structureId === 4 ? this.demopath1 : this.demopath2;

    // this.httpClient.get<any>(this.API_URL + "/getAllDemo").subscribe(
    this.httpClient.get<any>(this.API_URL + "/" + demopath1).subscribe(
      (data) => {
        this.dataChange.next(data);
        console.log(data);
        this.dataError = false;
      },
      (error: HttpErrorResponse) => {
        console.log("err");
        this.dataError = true;
        console.log(error.name + " " + error.message);
      }
    );
  }

  getExpectation(code, day): void {
    // console.log('getExpectation')
    this.httpClient.get<any>(this.API_URL + "/get/" + code).subscribe(
      (data) => {
        console.log(data);
        this.dataChange.next(data);
        this.dataError = false;
      },
      (error: HttpErrorResponse) => {
        this.dataChange.next([]);
        this.dataError = true;
        console.log("err", this.dataError);
        console.log(error.name + " " + error.message);
      }
    );
  }

  // DEMO ONLY, you can find working methods below
  addIssue(issue: Issue): void {
    this.dialogData = issue;
  }

  updateIssue(issue: Issue): void {
    this.dialogData = issue;
  }

  deleteIssue(id: number): void {
    console.log(id);
  }
}

/* REAL LIFE CRUD Methods I've used in my projects. ToasterService uses Material Toasts for displaying messages:

    // ADD, POST METHOD
    addItem(kanbanItem: KanbanItem): void {
    this.httpClient.post(this.API_URL, kanbanItem).subscribe(data => {
      this.dialogData = kanbanItem;
      this.toasterService.showToaster('Successfully added', 3000);
      },
      (err: HttpErrorResponse) => {
      this.toasterService.showToaster('Error occurred. Details: ' + err.name + ' ' + err.message, 8000);
    });
   }

    // UPDATE, PUT METHOD
     updateItem(kanbanItem: KanbanItem): void {
    this.httpClient.put(this.API_URL + kanbanItem.id, kanbanItem).subscribe(data => {
        this.dialogData = kanbanItem;
        this.toasterService.showToaster('Successfully edited', 3000);
      },
      (err: HttpErrorResponse) => {
        this.toasterService.showToaster('Error occurred. Details: ' + err.name + ' ' + err.message, 8000);
      }
    );
  }

  // DELETE METHOD
  deleteItem(id: number): void {
    this.httpClient.delete(this.API_URL + id).subscribe(data => {
      console.log(data['']);
        this.toasterService.showToaster('Successfully deleted', 3000);
      },
      (err: HttpErrorResponse) => {
        this.toasterService.showToaster('Error occurred. Details: ' + err.name + ' ' + err.message, 8000);
      }
    );
  }
*/
