import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { BehaviorSubject, Observable, merge } from "rxjs";
import { map } from "rxjs/operators";
import { Issue } from "../models/issue";
import { DataService } from "../services/data.service";

export class RRDataSource extends DataSource<Issue> {
  _filterChange = new BehaviorSubject("");

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: Issue[] = [];
  renderedData: Issue[] = [];
  public loading: boolean = true;

  constructor(
    public _exampleDatabase: DataService,
    public _paginator: MatPaginator,
    public _sort: MatSort,
    public structureId?: string,
    public date?: string
  ) {
    super();
    console.log("constructor", structureId);
    // Reset to the first page when the user changes the filter.
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(value): Observable<Issue[]> {
    console.log("connect", value);
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [this._exampleDatabase.dataChange, this._sort.sortChange, this._filterChange, this._paginator.page];
    if (this.date && this.structureId) this._exampleDatabase.getExpectation(this.structureId, this.date);
    else this._exampleDatabase.getAllIssues(this.structureId);

    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._exampleDatabase.data.slice().filter((issue: Issue) => {
          this.loading = false;
          const searchStr = issue.day;
          return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        });

        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());

        // Grab the page's slice of the filtered sorted data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
        return this.renderedData;
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  sortData(data: Issue[]): Issue[] {
    if (!this._sort.active || this._sort.direction === "") {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = "";
      let propertyB: number | string = "";

      switch (this._sort.active) {
        case "id":
          [propertyA, propertyB] = [a.id, b.id];
          break;
        // case 'title': [propertyA, propertyB] = [a.title, b.title]; break;
        case "state":
          [propertyA, propertyB] = [a.state, b.state];
          break;
        // case 'url': [propertyA, propertyB] = [a.url, b.url]; break;
        case "data":
          [propertyA, propertyB] = [a.data, b.data];
          break;
        case "rooms_sold":
          [propertyA, propertyB] = [a.rooms_sold, b.rooms_sold];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === "asc" ? 1 : -1);
    });
  }
}
