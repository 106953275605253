import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "invalid",
})
export class InvalidNumberPipe implements PipeTransform {
  transform(value: any): any {
    if (value === undefined || value === -999) return "--";
    return value;
  }
}
