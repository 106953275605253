import {
  Component,
  ElementRef,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  contactEmail = "Info@revenuerecovery.it";
  bodyEmail =
    "Salve, vorrei essere contattato per avere maggiori informazioni.%0D%0D Lascio di seguito i miei recapiti : %0D%0D Tel :";
  subjectEmail = "Richiesta Demo RevenueRecovery";

  emailstring = `mailto:${this.contactEmail}?Subject=${this.subjectEmail}&body=${this.bodyEmail}`;

  // emailstring = `mailto:{contactEmail}?Subject=Hello&body=links:  %0D http://link1.com  %0D http://link1.com`;

  ngOnInit(): void {}

  sendMail() {
    var anchor = document.getElementById("link");
    anchor.click();
  }
}
