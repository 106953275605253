import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingComponent } from "./pages/landing/landing.component";
import { MainComponent } from "./pages/main/main.component";

const routes: Routes = [
  {
    path: "",
    component: LandingComponent,
  },
  {
    path: "home",
    component: MainComponent,
  },
  {
    path: "landing",
    component: LandingComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
